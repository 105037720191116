import React from "react";
import { Link } from "react-router-dom";

export const CtaAreaUK = () => {
  return (
    <section className="cta-area-three">
      <div className="container">
        <div className="cta-inner-wrap-three">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="cta-content">
                <div className="cta-info-wrap cta-info-wrap-two">
                  <div className="icon">
                    <i className="flaticon-phone-call"></i>
                  </div>
                  <div className="content">
                    <span>Our U.K. Call Center</span>
                    <a href="tel:+442032896883">020-3289-6883</a>
                  </div>
                </div>
                <h2 className="title">
                  Request a discovery call, to see if Nomadic Flow can work for you...
                </h2>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="cta-btn text-end">
                <a className="btn btn-three" href="https://calendar.app.google/ExQQCXWJPV6s4BYL9" target="_blank">
                  Get In Touch
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
