import React from "react";
import { CHECK_ICON03 } from "../../lib/assets";
import { Link } from "react-router-dom";

export const PricingAreaUKItem = ({ isMonthly, item }) => {
  return (
    <div className="pricing-box-three">
      <div className="pricing-icon">        
        {isMonthly ? (
          <i className={item.icon.nomadic}></i>
        ) : (
          <i className={item.icon.branded}></i>
        )}
      </div>

      <div className="pricing-plan">        
        {isMonthly ? (
          <h4 className="title">{item.title.nomadic}</h4>
        ) : (
          <h4 className="title">{item.title.branded}</h4>
        )}
        
      </div>

      <div className="pricing-price-two">
        {isMonthly ? (
          <div>
            <h2 className="price monthly_price">
              {item.prices.nomadic}
              <strong>%</strong>
            </h2>
            <h2 className="price mt-10">
              <span>of incoming volume</span>
            </h2>
          </div>
        ) : (
          <div>
            <h2 className="price annual_price">
              {item.prices.branded}
              <strong>%</strong>
            </h2>
            <h2 className="price mt-10">
              <span>of incoming volume</span>
            </h2>
          </div>
        )}
      </div>

      <div className="pricing-list">
        <ul className="list-wrap">
        
        
        {isMonthly ? (
          item.plans.nomadic.map((el, idx) => (
            <li key={idx}>
              <img src={CHECK_ICON03} alt="" />
              {el}
            </li>
          ))
        ) : (
          item.plans.branded.map((el, idx) => (
            <li key={idx}>
              <img src={CHECK_ICON03} alt="" />
              {el}
            </li>
          ))
        )}
        </ul>
      </div>
      
      <div className="pricing-price-two">
        {isMonthly ? (
          <h2 className="price monthly_price">
            <strong>£</strong>
            {item.setup.nomadic}
            <span>/setup</span>
          </h2>
        ) : (
          <h2 className="price annual_price">
            <strong>£</strong>
            {item.setup.branded}
            <span>/setup</span>
          </h2>
        )}
      </div>

      <div className="pricing-btn-two">
        <a className="btn transparent-btn-two" href="https://calendar.app.google/ExQQCXWJPV6s4BYL9" target="_blank">
          Get Started Now
        </a>
      </div>
    </div>
  );
};
