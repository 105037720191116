import React, { useEffect } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { LOGO } from "../../lib/assets";

export const Sidebar = () => {
  useEffect(() => {
    $(".menu-tigger").on("click", function () {
      $(".extra-info,.offcanvas-overly").addClass("active");
      return false;
    });
    $(".menu-close,.offcanvas-overly").on("click", function () {
      $(".extra-info,.offcanvas-overly").removeClass("active");
    });
  }, []);

  return (
    <>
      <div className="extra-info">
        <div className="close-icon menu-close">
          <button>
            <i className="far fa-window-close"></i>
          </button>
        </div>
        <div className="logo-side mb-30">
          <Link to="/">
            <img src={LOGO} alt="Nomadic Tax Platform" />
          </Link>
        </div>
        <div className="side-info mb-30">
        <div className="contact-list mb-30">
          <h4>Nomadic Flow Versions</h4>
          <p>
            <a href="us">United States Persons 🇺🇸</a>
          </p>
          <p>
           <a href="uk">United Kingdom Residents 🇬🇧</a>
          </p>
          <p>
           <a href="expats">Global Expats (Non-US) 🗺️</a>
          </p>
        </div>
        <div className="contact-list mb-30">
          <h4>Our Other Solutions</h4>
          <p>
            <a href="https://nomadicx.com" target="_blank">NomadicX (Strategy)</a>
          </p>
          <p>
           <a href="http://nomadicgo.com" target="_blank">Nomadic Go (Services)</a>
          </p>
          <p>
           <a href="http://nomadictax.org" target="_blank">Nomadic Tax (Solutions)</a>
          </p>
        </div>
          {/* <div className="contact-list mb-30">
            <h4>Office Address</h4>
            <p>
              Singapore & California
            </p>
          </div> */}
          <div className="contact-list mb-30">
            <h4>Phone Number</h4>
            <p>US: <a href="tel:+14156551066">415-655-1066</a></p>
            <p>UK: <a href="tel:+442032896883">020-3289-6883</a></p>
          </div>
          <div className="contact-list mb-30">
            <h4>Email Address</h4>
            <p><a href="mailto:hello@trynomadic.com">hello@trynomadic.com</a></p>
            <p><a href="mailto:hello@nomadictax.org">hello@nomadictax.org</a></p>
          </div>
        </div>
        <div className="social-icon-right mt-30">
          <a target="_blank" href="https://www.linkedin.com/showcase/nomadictax">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a target="_blank" href="https://www.linkedin.com/showcase/nomadictax">
            <i className="fab fa-twitter"></i>
          </a>
          <a target="_blank" href="https://www.linkedin.com/showcase/nomadictax/">
            <i className="fab fa-linkedin"></i>
          </a>
          <a target="_blank" href="https://www.linkedin.com/showcase/nomadictax/">
            <i className="fab fa-instagram"></i>
          </a>
          <a target="_blank" href="https://www.linkedin.com/showcase/nomadictax/">
            <i className="fab fa-youtube"></i>
          </a>
        </div>
      </div>
      <div className="offcanvas-overly"></div>
    </>
  );
};
